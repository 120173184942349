<template>
  <div class="bg-gray-200">
    <Html class="h-full bg-white">
      <Head>
        <Title>WeTix Console</Title>
      </Head>
    </Html>

    <Body class="h-full" />
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden overflow-auto" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-[#2130b8] px-8">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <MenuAlt1Icon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="text-[36px] text-white flex justify-left">
              <img src="~/assets/wetix-logo-white.svg" class="object-contain object-left-top w-28" alt="WeTix">
            </div>
            <div class="mt-5">
              <nav class="space-y-3">
                <div v-for="(item, i) in navigation" :key="i">
                  <NuxtLink
                    v-if="item"
                    :to="item.href"
                    active-class="bg-[#ffffff36] hover:bg-[#ffffff36]"
                    class="text-white group grid grid-cols-6 items-center px-2 py-2 text-base font-medium rounded-full"
                    @click="sidebarOpen = false"
                  >
                    <component
                      :is="item.icon"
                      class="mr-4 flex-shrink-0 h-6 w-6 text-white col-span-1"
                      aria-hidden="true"
                    />
                    <div class="col-span-4">
                      {{ item.name }}
                    </div>
                  </NuxtLink>

                  <div v-if="item.lineBreak" class="pt-4 pb-2">
                    <hr class="hr">
                  </div>
                </div>
              </nav>
            </div>
            <div style="font-size: 14px" class="flex flex-col justify-center mt-20 text-white w-full">
              <div class="w-full">
                <div class="font-bold">
                  WeTix Events Platform
                </div>
                <div>© WeTix Ltd 2022 All Rights Reserved</div>
              </div>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true" />
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 overflow-auto">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow pt-5 bg-[#2130b8] px-4">
        <div class="text-[36px] text-white flex justify-left w-3/4 mb-2">
          <img src="~/assets/wetix-logo-white.svg" alt="Wetix" class="ml-2 object-contain object-left-top w-28">
        </div>
        <div class="mt-5">
          <nav class="space-y-3">
            <div v-for="(item, i) in navigation" :key="i">
              <NuxtLink
                v-if="item"
                :to="item.href"
                active-class="bg-[#ffffff36]"
                class="text-white group grid grid-cols-6 items-center px-2 py-2 text-base font-medium rounded-full hover:bg-[#ffffff36]"
              >
                <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-white col-span-1" aria-hidden="true" />
                <div class="col-span-4">
                  {{ item.name }}
                </div>
              </NuxtLink>

              <div v-if="item.lineBreak" class="pt-4 pb-2">
                <hr class="hr">
              </div>
            </div>
          </nav>
        </div>
        <div class="flex flex-col justify-center mt-20 text-white w-full">
          <div class="w-full px-2">
            <div class="font-bold">
              WeTix ®
            </div>
            <div class="text-xs font-medium">
              © WeTix Ltd - All Rights Reserved
            </div>
            <div class="mt-2 text-xs font-thin truncate text-opacity-50">
              {{ config.public['ENVIRONMENT'] }}
            </div>
            <div class="text-xs font-thin truncate text-opacity-50">
              {{ config.public.releaseSHA }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1 h-screen">
      <!-- Header -->
      <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button type="button" class="px-4 text-gray-500 md:hidden" @click="sidebarOpen = !sidebarOpen">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt1Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <div class="flex-1 px-4 flex justify-between">
          <div class="flex">
            <div class="flex-1 flex items-center p-2">
              <div class="flex-1 flex items-center justify-center px-2">
                <div class="max-w-lg w-full lg:max-w-xs flex">
                  <div class="relative">
                    <!-- GLOBAL SEARCH BOX -->
                    <input
                      id="wetix-global-search-input"
                      name="search"
                      class="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-full leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
                      placeholder="Search everyting"
                    >
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <div class="flex">
            <div
              class="w-10 h-10 rounded-full text-[#2130B8] bg-[#ECEEFF] flex items-center justify-center"
              style="cursor: pointer"
            >
              <BellIcon
                class="w-5 h-5"
                @click="$notify({ timeout: 2000, title: 'All clear!', message: `You don't have any pending notifications.` })"
              />
            </div>
            <!-- Notification animation -->
            <div v-if="notificationStore.hasNotifications" class="-ml-3">
              <span class="flex h-3 w-3">
                <span class="animate-ping absolute inline-flex w-3 h-3 rounded-full bg-yellow-300 opacity-75" />
                <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-300" />
              </span>
            </div>
            <!-- Profile dropdown -->
            <LayoutHeaderUserProfileDropdown />
          </div>
        </div>
      </div>

      <main class="bg-[#FCFCFC] h-full">
        <div class="">
          <slot />
        </div>
        <client-only>
          <SearchOverlay />
        </client-only>
        <NotificationBasic />
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt1Icon,
  HomeIcon,
  TicketIcon,
  UserIcon,
  CogIcon,
  InformationCircleIcon,
  ClipboardListIcon,
  CollectionIcon,
  SaveIcon,
  DotsVerticalIcon
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'

// Import stores
import { useActiveElement, useMagicKeys } from '@vueuse/core'
import { useSearchStore } from '~/stores/search'
import { useNotificationState } from '~/stores/notifications'

// Import useActiveElement from vueUse to activate the global search dialog when search box is clicked
// Use onKeyStroke to launch the command box when ctrl + / is pressed.

// // All admin pages should be passed through the authCheck middleware.
// definePageMeta({
//   // Use the auth middleware to check where the user should be redirected to after logging in.
//   // middleware: ["authadminonly"]
// })

// Composable to watch ID token and set cookie.
useIDTokenChange()

// Nuxt app context
const nuxtApp = useNuxtApp()

// Pinia search store
const searchStore = useSearchStore()

// Pinia notification store
const notificationStore = useNotificationState()

// Config
const config = useRuntimeConfig()

// Detect if the search box is clicked and launch the global search dialog if so.
const activeElement = useActiveElement()
watch(activeElement, (el) => {
  if (el.id === 'wetix-global-search-input') {
    console.log('tenant-admin layout - Launching global search input')
    searchStore.openSearchOverlay()
  }
  // else {
  //   console.log(`Focus changed to: ${el.id}`)
  // }
})

// Listen for ctrl + / keystroke to launch the command box
const keys = useMagicKeys()
const ctrlForwardslash = keys['Ctrl+K']
watch(ctrlForwardslash, (v) => {
  if (v) { console.log('Ctrl + / have been pressed. ') }
  if (v && searchStore.showGlobalSearchOverlay) {
    console.log('Closing global search box')
    searchStore.closeSearchOverlay()
  } else if (v && !searchStore.showGlobalSearchOverlay) {
    console.log('Opening global search box')
    searchStore.openSearchOverlay()
  }
})

const navigation = [
  {
    name: 'Dashboard',
    href: '/app/tenant-admin',
    icon: HomeIcon,

    lineBreak: true
  },
  {
    name: 'Events',
    href: '/app/tenant-admin/events',
    icon: ClipboardListIcon
  },
  {
    name: 'Orders',
    href: '/app/tenant-admin/orders',
    icon: CollectionIcon

  },
  {
    name: 'Customers',
    icon: UserIcon,
    href: '/app/tenant-admin/customers',
    lineBreak: true
  },
  {
    name: 'Coupons',
    icon: TicketIcon,
    href: '/app/tenant-admin/coupons',
    lineBreak: true
  },
  // {
  //   name: "Tickets",
  //   icon: TicketIcon,
  //   current: false,
  //   subLinks: [
  //     { name: "Add New", href: "/app/tenant-admin/" },
  //     { name: "Check Schedule", href: "/app/tenant-admin/" },
  //     // { name: "Orders", href: "/app/tenant-admin/tickets/orders" },
  //   ],
  // },
  {
    name: 'Integrations',
    href: '/app/tenant-admin/integrations',
    icon: SaveIcon,
    current: false
  },
  {
    name: 'Settings',
    href: '/app/tenant-admin/settings',
    icon: CogIcon,
    current: false
  },
  {
    name: 'Help',
    href: '/app/tenant-admin/help',
    icon: InformationCircleIcon,
    current: false
  },
  {
    name: 'More',
    href: '/app/tenant-admin/more',
    icon: DotsVerticalIcon,
    current: false
  }
  // {
  //   name: "Logout",
  //   href: "/app/tenant-admin/logout",
  //   icon: LogoutIcon,
  //   current: false,
  // },
]

const sidebarOpen = ref(false)

</script>

<style scoped>
hr {
  border-top: 1px solid #444fb6;
}
</style>
